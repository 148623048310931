'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.devices.factory:DeviceStatusManager

 # @description

###
angular
  .module 'mundoAdmin.devices'
  .factory 'DeviceStatusManager', [
    'MundoDefaultManager'
    (MundoDefaultManager)->
      DeviceStatusManagerBase = new MundoDefaultManager()

      DeviceStatusManagerBase.conf.url = 'devices/statuses'
      DeviceStatusManagerBase.actions = ['list', 'print']

      DeviceStatusManagerBase
  ]
